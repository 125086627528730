import LoginPage from '../views/login/Login'
import HomePage from '../views/home/HomePage'

export default {
  // apiServer:'http://localhost:4080/',
  apiServer:'https://demo-water-monitor.bodaiot.com/api/',
  theme: 'light',
  layout: 'side',
  logo:"",
  loginTitle: '智能云水质管家',
  systemName: '智能云水质管家',
  copyright: '智能云水质管家',
  multipage: false,
  fixSiderbar: true,
  fixHeader: true,
  color: '#27d85a',

  loginPage:LoginPage,
  homePage:HomePage
}

