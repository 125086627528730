<template>
    <div class="layout">

        <div class="layout-header">
            <div class="title">
                智能云水质管家
            </div>
            <div style="flex: 1;height: 40px">

            </div>
            <header-avatar></header-avatar>
        </div>

        <div class="layout-body">
            <div class="sider">
                <recursion-menu :theme="theme" :menuData="menuData" :collapsed="true"/>
            </div>

            <div class="content">
                <router-view></router-view>
            </div>
            <!--      <a-layout-footer style="padding: .29rem 0" class="copyright">-->
            <!--        <global-footer :copyright="copyright"/>-->
            <!--      </a-layout-footer>-->
        </div>
    </div>
</template>

<script>
    import HeaderAvatar from "./HeaderAvatar";
    import RecursionMenu from "../menu/RecursionMenu";
    import {mapState, mapMutations} from 'vuex'


    let menuData = []

    export default {
        name: 'GlobalLayout',
        components: {HeaderAvatar,RecursionMenu},
        data() {
            return {
                collapsed: false,
                menuData: menuData,
            }
        },
        computed: {

            ...mapState({
                sidebarOpened: state => state.setting.sidebar.opened,
                isMobile: state => state.setting.isMobile,
                theme: state => state.setting.theme,
                layout: state => state.setting.layout,
                copyright: state => state.setting.copyright,
                fixSiderbar: state => state.setting.fixSiderbar,
                fixHeader: state => state.setting.fixHeader,
                settingBar: state => state.setting.settingBar.opened
            })
        },
        methods: {
            ...mapMutations({setSidebar: 'setting/setSidebar'}),
            toggleCollapse() {
                this.collapsed = !this.collapsed
                this.setSidebar(!this.collapsed)
                triggerWindowResizeEvent()
            },
            onDrawerChange(show) {
                this.collapsed = show
            },
            onMenuSelect() {
                this.toggleCollapse()
            }
        },
        beforeCreate() {
            let routers = this.$db.get('USER_ROUTER')
            menuData = routers.find((item) => item.path === '/').children.filter((menu) => {
                console.log(menu.name + ":" + menu.hidden)
                return !menu.hidden
                let meta = menu.meta
                if (typeof meta.isShow === 'undefined') {
                    return true
                } else return meta.isShow
            })
        },

    }
</script>

<style lang="less" scoped>
    .setting {
        background-color: #1890ff;
        color: #fff;
        border-radius: 5px 0 0 5px;
        line-height: 40px;
        font-size: 22px;
        width: 40px;
        height: 40px;
        box-shadow: -2px 0 8px rgba(0, 0, 0, 0.15);
    }

    .fixed-header-content {
        margin: 60px 0px 20px !important;
        /*border-radius: 5px;*/
        /*box-shadow:  0 0 10px rgba(0,0,0,0.2);*/
        overflow: hidden;
    }


    .layout {
        position: fixed;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        background: transparent;
    }

    .layout-header {
        background: rgba(255, 255, 255, 0.9);
        box-shadow: 0 0 10px  rgba(0, 0, 0, 0.3);
        display: flex;
        align-items: center;
        padding: 6px 25px;
        z-index: 2;
        font-weight: 500;

        .title{
            width: 200px;
            font-size: 18px;
            color: #164c97;
        }
    }

    .layout-body {
        flex: 1;
        display: flex;
        background: transparent;
        overflow-y: auto;


        .sider {
            width: auto;
            height: 100%;
            overflow-y: auto;
            background: rgba(255, 255, 255, 0.1);
            /*box-shadow: 0 0 5px #888;*/
            padding-top: 20px;
            border-right: 1px solid #EEE;

            scrollbar-width: none; /* firefox */
            -ms-overflow-style: none; /* IE 10+ */
            overflow-x: hidden;
            overflow-y: auto;
        }

        .sider::-webkit-scrollbar {
            display: none; /* Chrome Safari */
        }

        .content {
            flex: 1;
            height: 100%;
            overflow-y: auto;
            background: transparent;
        }
    }

</style>

